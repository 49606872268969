<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-breakpoint="1920"
    app
    width="260"
    v-bind="$attrs">
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props"/>
    </template>

    <v-divider class="mb-1"/>

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center rounded" color="white" contain>
          <v-img :src="require(`@/assets/icons/favicon.png`)" max-height="30"/>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2"/>
    <v-list expand nav>
      <div/>
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item">
        </base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item"/>
      </template>
      <div/>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-monitor-dashboard',
          title: 'dashboard',
          to: '/',
        },
        {
          icon: 'mdi-clipboard-list-outline',
          title: 'orders',
          to: '/ordermenu',
        },
        {
          icon: 'mdi-file-multiple',
          title: 'files',
          to: '/files',
        },
        {
          icon: 'mdi-domain',
          title: 'agencies',
          to: '/agencymenu',
        },
        {
          icon: 'mdi-account-details',
          title: 'managers',
          to: '/managers',
        },
        {
          icon: 'mdi-account-multiple',
          title: 'payers',
          to: '/payers',
        },
        {
          icon: 'mdi-calendar-check-outline',
          title: 'tasks',
          to: '/tasks',
        },
        {
          icon: 'mdi-receipt-text-check-outline',
          title: 'invoicing',
          to: '/invoicing',
        },
        {
          icon: 'mdi-calendar-multiple',
          title: 'calendar',
          to: '/calendar',
        },
        {
          icon: 'mdi-cash-multiple',
          title: 'finance',
          to: '/finance',
        },
        {
          icon: 'mdi-format-list-checks',
          title: 'references',
          to: '/references',
        },
        {
          icon: 'mdi-trash-can-outline',
          title: 'recycle bin',
          to: '/recyclebin',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get() {
          return this.$store.state.drawer
        },
        set(val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems() {
        return this.items.map(this.mapItem)
      },
      profile() {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {
      mapItem(item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  /*@import '~vuetify/src/styles/tools/_rtl.sass'*/

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
